@import "./colors";

.select_list {
  width: 100%;
  border: none;
  height: 30px;
  cursor: pointer;
  font-size: 11.7px;
  position: relative;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: $dark-blue-font;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;

  &:hover {
    background-color: rgba(221, 221, 221, 0.6);
  }

  &.error {
    border-left: 4px solid $error;
  }

  span {
    order: -1;
  }


  div {
    left: 0;
    top: 31px;
    z-index: 4;
    width: 100%;
    position: absolute;
    background-color: #f5f5f5;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.12);

    li {

      height: 40px;
      display: flex;
      padding: 0px 10px;
      align-items: center;

      &:hover,
      &.active {
        color: #fff;
        background-color: $edutin-blue;
      }
    }
  }
}




.select_list_2 {
  input {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  height: 42px;
  cursor: pointer;
  font-size: 15px;
  position: relative;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: $dark-blue-font;
  background-color: transparent;

  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;


  &:hover {
    background-color: rgba(221, 221, 221, 0.6);
  }

  &.error {
    border-left: 4px solid $error;
  }

  span {
    order: -1;
  }

  input[type='checkbox'] {
    &:checked+ul {
      display: flex;
    }

    &:not(:checked)+ul {
      display: none;
    }
  }

  ul {
    left: 0;
    top: 31px;
    z-index: 6;
    width: 100%;
    position: absolute;
    flex-direction: column;
    background-color: #f5f5f5;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.12);

    li {

      height: 40px;
      display: flex;
      padding: 0px 10px;
      align-items: center;

      &:hover,
      &.active {
        color: #fff;
        background-color: $edutin-blue;
      }
    }
  }


  &.disabled {

    pointer-events: none;
    color: #ccc;
  }
}
