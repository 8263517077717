.btn-group {
  display: flex;
  height: fit-content;
  justify-content: flex-end;
  align-items: center;

  .btn:first-of-type {
    margin-right: 8px;
  }
}

button {
  &:focus {
    outline: none;
  }
}

.btn {

  font-size: 15px;
  width: fit-content;
  height: fit-content;
  border: 0;
  display: flex;
  color: #fff;
  position: relative;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;


  &.btn__blue {
    color: #fff;
    border: none;
    outline: none;
    background-color: $blue;

    &:hover {
      // background-color: $light-blue;
      opacity: .86;
    }
  }

  &.btn__dark-blue {
    color: #fff;
    border: none;
    outline: none;
    background-color: $edutin-blue;


    &:hover {
      overflow: hidden;
      background-color: #0056bf;

    }

  }

  &.btn__ligth-blue {
    color: #fff;
    border: none;
    outline: none;
    background-color: $light-blue;

    &:hover {
      background-color: $edutin-blue;
    }
  }


  &.btn__disabled {
    color: #fff;
    pointer-events: none;
    background-color: $gray;
  }

  &.btn__cancel {
    // color: $font-gray;
    color: rgba(3, 27, 78, .8);
    font-weight: 400;

    &:hover {
      color: $edutin-blue;
    }
  }

  &.btn__info {
    color: $edutin-blue;
    font-weight: 400;

    &:hover {
      color: #0056bf;
    }
  }

  &.btn__outline {
    color: #031b4e;
    border: 1px solid #031b4e;

    &:hover {
      color: $edutin-blue;
      border: 1px solid $edutin-blue;
    }
  }

  &,
  * {
    cursor: pointer;
  }
}
