@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");
app-courses,app-course,app-admin{
  body {
    margin: 0;
    font-family: "Muli", "sans-serif";
    color: #031b4e;
    max-width: 100%;
    overflow: hidden;
    background-color: #f3f5f9;
    font-size: 15px;
  }
  .designer a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  // .designer h2,
  // .designer h3,
  // .designer h4 {
  //   font-weight: 400;
  // }

  .designer .menu-designer .dropdown,
  .designer .menu-designer .img-profile,
  .designer .menu-designer .dropdown .dropbtn,
  .designer .setting-course .options--s,
  .designer .setting-course .setting-general .img-course .state .--state {
    display: flex;
  }

  .designer .back-white {
    background-color: #fff;
  }
  .designer .back-blue {
    background-color: #f6f9fc;
  }

  .designer button {
    cursor: pointer;
    font-family: "Muli", "sans-serif";
    outline: none;
    font-size: 16px;
    color: #031b4e;
    background-color: transparent;
  }
  .designer button:hover,
  .designer .setting-course .--archive .--information table a.active:hover {
    text-decoration: none;
    transition: background-color 0.2s linear;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
  }
  .designer .setting-course label.transparent:hover,
  .designer .setting-course .setting-general .btn-tsl a:hover,
  .designer .setting-course .setting-content .module button:hover.save {
    background-color: #0056bf;
    border: 1px solid #0056bf;
    color: #fff;
  }
  .designer .setting-course .--archive .--information table a.active:hover {
    color: #2979ff;
  }
  .designer .setting-course .setting-content .module button:hover.cancel {
    font-weight: 600;
  }
  /* start admin-general */
  //.designer .setting-course {
  //  padding: 40px 2rem 0;
  //  box-sizing: content-box;
  //}

  .designer .setting-course .--archive .--information .--question:hover,
  .designer .setting-content .added-class .--class-item:hover,
  .designer .setting-content .--class-item.--class:hover {
    border: 1px solid #0056bf;
    align-items: center;
  }


  // contenido de los botones
  .content-botton {
    display: flex;
    justify-content: flex-end;

    & > .btn {
      cursor: pointer;
      height: 40px;
      display: flex;
      color: #fff;
      min-width: 90px;
      align-items: center;
      align-self: flex-end;
      justify-content: center;
      background-color: #1d448e;
      &:hover {
        background-color: #0056bf;
        transition: background-color 150ms linear;
      }
    }

    & > .btn.cancelar {
      cursor: pointer;
      height: 40px;
      display: flex;
      color: #031b4e;
      background-color: transparent;
      min-width: 90px;
      align-items: center;
      align-self: flex-end;
      justify-content: center;
      &:hover {
        font-weight: bold;
        transition: font-weight 150ms linear;
      }
    }
  }

  /* end admin-general */

  /* start pagination */
  .designer .setting-course .setting-content .text-size {
    margin-left: auto;
    color: rgba(3, 27, 78, 0.8);
  }

  /* end pagination */
  /* start setting-course */
  .designer .setting-course .container--s .setting-content {
    .edit_class {
      padding: 0 20px;
      overflow-y: auto;
      height: 0%;
    }
  }

  .designer .setting-course.modal-new-tab-active > .header {
    display: none;
  }

  .designer .setting-course .return-admin,
  .designer .setting-course .setting-general .img-course .state {
    cursor: pointer;
    position: relative;
  }

  .return-admin i {
    margin: 6px 8px auto 0;
    font-size: 1.6rem;
    color: #0a1b4e;
  }

  .return-admin h2 {
    margin: 0;
  }

  .designer .setting-course .options--s {
    margin-left: auto;
  }

  .designer .setting-course .options--s .tablinks {
    padding: 8px 12px;
    border-radius: 3px;
    cursor: pointer;
  }
  .designer .setting-course .options--s .tablinks.active {
    background-color: #fff;
  }

  .designer .setting-course .setting-content .item.select {
    margin: 0;
    background-color: transparent;
  }
  .designer .setting-course .setting-general .item h4,
  .designer .setting-course .setting-general .img-course h4,
  .designer .setting-course .setting-content .module .c-massive-loader h4,
  .designer .setting-course .--archive .--information h4 {
    margin: 0;
    color: rgba(3, 27, 78, 0.6);
  }
  .designer .setting-course .setting-general input,
  .designer .setting-course .setting-general textarea,
  .designer .setting-course .setting-content textarea {
    min-height: 40px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 20px);
    font-family: "Muli", "sans-serif";
    font-size: 15px;
    color: #031b4e;
    line-height: 1.6;
    padding: 0;
    outline: none;
  }
  .designer .setting-course .setting-general textarea,
  .designer .setting-course .setting-content textarea {
    min-height: 100px;
    padding-top: 10px;
    resize: none;
    vertical-align: baseline;
  }
  .designer .setting-course .setting-content textarea {
    border: 1px solid rgba(3, 27, 78, 0.2);
    width: -webkit-fill-available;
    padding: 7px;
  }
  .designer .setting-course .setting-content textarea.small {
    min-height: 40px;
  }
  .designer .setting-course .setting-general .item:focus,
  .designer .setting-course .setting-content textarea:focus {
    border: 1px solid #0056bf;
  }
  .designer .setting-course .setting-content textarea::-webkit-input-placeholder {
    /* Edge */
    color: rgba(3, 27, 78, 0.5);
  }
  .designer .setting-course .setting-content textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(3, 27, 78, 0.5);
  }
  .designer .setting-course .setting-content textarea::-moz-placeholder {
    color: rgba(3, 27, 78, 0.5);
  }
  .designer .setting-course .setting-content textarea::-ms-input-placeholder {
    color: rgba(3, 27, 78, 0.5);
  }
  .designer .setting-course .setting-content textarea::placeholder {
    color: rgba(3, 27, 78, 0.5);
  }

  .designer .setting-course label.transparent {
    font-size: 15px;
    border: 1px solid rgba(3, 27, 78, 0.2);
    color: #031b4e;
    padding: 8px;
  }
  .designer .setting-course .setting-content .module .c-massive-loader .divider {
    display: table;
    white-space: nowrap;
    height: auto;
    font-size: 20px;
    margin: 10px 0;
  }
  .designer .setting-course .setting-content .module .c-massive-loader .divider:after,
  .designer .setting-course .setting-content .module .c-massive-loader .divider:before {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
  }
  .designer .setting-course .setting-general .img-course .card .divider:before,
  .designer .setting-course .setting-content .module .c-massive-loader .divider:before {
    background-position: right 1em top 50%;
  }
  .designer .setting-course .setting-general .img-course .card .divider:after,
  .designer .setting-course .setting-content .module .c-massive-loader .divider:after {
    background-position: left 1em top 50%;
  }
  .designer .setting-course .setting-content .module {
    padding: 0 16px 16px;
  }
  .designer .setting-course .setting-content .modal-new-tab .module {
    padding: 0;
  }
  .designer .setting-course .setting-content .module .massive-loader {
    padding: 20px;
    max-width: 300px;
    margin: 0 auto;
    border-radius: 4px;
    text-align: center;
  }
  .designer .setting-course .setting-content .module .content-module {
    border: 1px solid #0056bf;
    padding: 10px;
    border-radius: 4px;
  }
  .designer .setting-course .setting-content .modal-new-tab .module .content-module {
    padding: 16px 0;
  }
  .designer .setting-course .setting-content .module .buttons {
    text-align: right;
    margin-top: 10px;
  }
  .designer .setting-course .setting-content .modal-new-tab .module .buttons {
    margin-top: 20px;
    padding: 0 16px;
  }
  .designer .setting-course .setting-content .modal-new-tab .module .--archive .buttons {
    padding: 0;
  }
  .designer .setting-course .setting-content .module button {
    padding: 8px 20px;
  }
  .designer .setting-course .setting-content .module {
    background-color: #1d448e;
    border: 1px solid #1d448e;
    color: #fff;
    margin-bottom: 10px;
    height: 28px;
  }

  .designer .setting-course .setting-content .module,
  button.cancel {
    border: none;
    background-color: transparent;
    margin-bottom: 0px;
    height: 40px;
  }

 /*
  .designer .setting-course .setting-content .item {
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  */
  .designer .setting-course .setting-content .modal-new-tab .item {
    margin-bottom: 0;
    background-color: transparent;
  }

  .designer .setting-course .setting-content .item .header {
    .select_div {
      opacity: 0.3;
    }
  }
  .designer .setting-course .setting-content .item .header:hover,
  .designer .setting-content .add-class:hover {
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25), 0 1px 3px -1px rgba(0, 0, 0, 0.3);
  }
  .designer .setting-course .setting-content .item.add-after .header:hover,
  .designer .setting-course .setting-content .--archive .header:hover {
    box-shadow: none;
  }
  .designer .setting-course .setting-content .--archive .header .title-archive span,
  .designer .setting-course .setting-content .item.add-after .header .title span {
    color: #031b4e;
    font-weight: 600;
  }
  .designer .setting-course .setting-content .item .header > img {
    margin-left: auto;
  }
  .designer .setting-course .setting-content .item .header .title,
  .designer .setting-content .add-class .title {
    margin-left: 8px;
    color: #1d448e;
    font-weight: 600;
  }
  .designer .setting-course .setting-content .item.add-after .header .title {
    color: rgba(3, 27, 78, 0.8);
    font-weight: 400;
  }
  .designer .setting-course .setting-content .item.m-loader .header .title,
  .designer .setting-course .setting-content .item.add-after .header .title {
    margin-left: 0;
  }
  /* end setting-course */
  /* start select */
  .designer .custom-select select {
    display: none;
  }
  .designer .select-selected.select-arrow-active:after {
    border-color: transparent transparent #031b4e transparent;
    top: 12px;
  }

  .designer .select-hide {
    display: none;
  }
  .designer .same-as-selected {
    background-color: rgba(3, 27, 78, 0.1);
  }
  /* end select */
  /* start tab */
  .designer .setting-content .tab {
    position: relative;
    width: 100%;
  }
  .designer .setting-content .tab.accordion > label {
    position: relative;
    cursor: pointer;
  }
  .designer .setting-content .tab.accordion > label,
  .designer .setting-content .tab.accordion > .tab-content {
    background: 0 0;
    color: rgba(3, 27, 78, 0.8);
  }
  .designer .setting-content .tab.accordion > label:hover {
    border: 1px solid #0056bf;
    border-radius: 4px;
  }
  .designer .setting-content .tab.accordion > label,
  .designer .setting-content .tab.accordion > label::after {
    display: block;
  }
  .designer .setting-content .tab.accordion > label {
    border: 1px solid #f5f5f5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .designer .setting-content .tab.accordion > input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .designer .setting-content .tab.accordion > input[type="checkbox"]:checked + label {
    border-top: 1px solid #0056bf;
    border-right: 1px solid #0056bf;
    border-left: 1px solid #0056bf;
    border-bottom: 1px solid #f5f5f5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .designer .setting-content .tab.accordion.--unity-item > input[type="checkbox"] + label::after {
    content: "\e874";
    font-family: Linearicons-Free;
  }

  .designer .setting-content .tab.accordion.--unity-item > input[type="checkbox"]:checked + div.module-name span {
    position: relative;
    transform: rotate(180deg);
  }

  .designer .setting-content .tab.accordion .tab-content {
    padding: 0 8px;
    //max-height: 0;
    overflow: hidden;
    // width: calc(100% - 32px);
  }

  .designer .setting-content .tab.accordion > input:checked ~ .tab-content {
    max-height: -webkit-min-content;
    max-height: -moz-min-content;
    max-height: min-content;
    padding: 0 16px 16px;
    border-bottom: 1px solid #0056bf;
    border-left: 1px solid #0056bf;
    border-right: 1px solid #0056bf;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .designer .setting-content .tab.accordion .--edit {
    position: absolute;
    top: 20px;
    right: 48px;
    cursor: pointer;
  }

  // .designer .setting-content .tab.accordion > input:checked ~ .--edit::after {
  //   // content: url(/Users/user/Desktop/edit.svg);
  //   // // uno de los errores que salen en consola es esto
  // }
  .designer .setting-content .tab.accordion > label::after {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 1rem;
    font-weight: 800;
  }

  /* end tab */
  .designer .setting-content .added-class .--class-item {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    margin-bottom: 10px;
  }
  .designer .setting-content .added-class .--class-item:last-of-type {
    margin-bottom: 0;
  }
  .designer .setting-content .--display .--checkbox {
    margin-bottom: 10px;
  }
  .designer .setting-content .--class-item.--class {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid rgba(3, 27, 78, 0.2);
    margin-top: 10px;
    padding: 16px;
    cursor: pointer;
    position: relative;
  }
  .designer .setting-content .--class-item.--class .header {
    margin-bottom: 0;
  }
  /* start checkbox */
  .designer .setting-course .--archive .select-answers input,
  .designer .setting-content .--display .--checkbox .style-check {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 12px;
    margin: 0;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid rgba(3, 27, 78, 0.2);
    padding: 8px;
    border-radius: 3px;
    outline: none;
  }
  .designer .setting-content input.style-check:checked {
    background-color: #1d448e;
    border: 1px solid #1d448e;
  }
  .designer .setting-content input.style-check:checked:after,
  .designer .setting-content .--display .--checkbox .style-check.active:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 3px;
    color: #fff;
  }
  .designer .setting-content .--display .--checkbox .style-check.active:after {
    top: -2px;
    color: #031b4e;
  }
  .designer .setting-content input.style-check.higher:checked:after {
    top: 1px;
    left: 4px;
  }
  .designer .setting-content .--display .--checkbox .style-check {
    top: 80px;
    left: 0;
    cursor: inherit;
    background-color: rgba(248, 231, 28, 0.2);
    border: 1px solid rgba(248, 231, 28, 0.8);
  }
  /* end checkbox */
  .designer .setting-content .a-class-h img {
    position: absolute;
    top: 8px;
    left: 50px;
    cursor: pointer;
  }
  .designer .setting-course .--archive .select-answers input {
    top: 2px;
    left: 0;
  }
  .designer .setting-course .--archive .select-answers label .correct,
  .designer .setting-content .--display .--checkbox .correct {
    padding: 0 0 0 28px;
  }
  .designer .setting-course .--archive {
    //background-color: #f5f5f5;
    background-color: white;
    border-radius: 4px;
    //margin-top: 10px;
    position: relative;
  }
  .designer .setting-course .--archive.add-question {
    border: 1px solid #0056bf;
    padding: 16px;
  }
  .designer .setting-course .modal-new-tab .--segment {
    &.edit {
      height: calc(100vh - 250px);
    }
    overflow-y: auto;
    padding: 0 16px;
  }
  .designer .setting-course .--archive .--information .advert strong {
    font-weight: 600;
    color: rgba(3, 27, 78, 0.5);
  }

  .designer .setting-course .--archive .--information .--question {
    border: 1px solid rgba(3, 27, 78, 0.2);
    border-radius: 4px;
    padding: 10px;
    position: relative;
    cursor: pointer;
    margin-bottom: 8px;
    flex-basis: calc(50% - 27px);
    display: flex;
    flex-direction: inherit;
    justify-content: space-between;
  }

  .designer .setting-course .--archive .--information table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
  }
  .designer .setting-course .--archive .--information table .load-state {
    display: inline-flex;
  }
  .designer .setting-course .--archive .--information table .load-state i {
    padding-top: 4px;
    margin-right: 6px;
  }
  .designer .setting-course .--archive .--information table thead tr,
  .designer .setting-course .--archive .--information table a {
    color: rgba(3, 27, 78, 0.6);
    cursor: inherit;
  }
  .designer .setting-course .--archive .--information table tbody {
    color: #031b4e;
    background-color: #fafafa;
  }
  .designer .setting-course .--archive .--information table td {
    padding: 10px;
    border: 1px solid rgba(3, 27, 78, 0.2);
  }
  .designer .setting-course .--archive .--information table td.small {
    width: 120px;
    text-align: right;
  }
  .designer .setting-course .--archive .--information table a.active {
    color: #0056bf;
    cursor: pointer;
  }
  .designer .setting-course .hover-options:hover .options-class i:first-of-type,
  .designer .setting-course .--archive .--information .--question .options-question i:first-of-type {
    margin-right: 12px;
  }
  .designer .setting-course .setting-content .options-class {
    display: none;
  }
  .designer .setting-course .hover-options:hover .options-class {
    display: flex;
  }
  .designer .setting-content .add-archive .add-class {
    margin: 0;
  }
  .designer .setting-content .add-archive > .row:last-of-type,
  .designer .setting-content .add-archive {
    margin-top: 10px;
  }
  .designer .setting-content .add-archive > .row > .column:first-of-type {
    margin-right: 10px;
  }
  .designer .setting-course .--display {
    position: relative;
  }
  .designer .setting-course .--display textarea,
  .designer .setting-course .setting-general .item.--display {
    background-color: rgba(248, 231, 28, 0.2);
    border: 1px solid rgba(248, 231, 28, 0.8);
  }

  .designer .setting-course .--display .--copy {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    font-weight: 600;
    color: rgba(3, 27, 78, 0.5);
    cursor: pointer;
    background-color: #fff;
    border-radius: 3px;
    padding: 2px 4px;
  }
  .designer .setting-course .--display .--copy:hover {
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25), 0 1px 3px -1px rgba(0, 0, 0, 0.3);
  }
  .designer .setting-course .--display .--copy img {
    margin-right: 6px;
  }

  .designer .setting-course .--display:hover .--copy {
    display: flex;
  }
  .designer .setting-course .--archive .--information table tbody tr.--display-t {
    background-color: rgba(248, 231, 28, 0.2);
  }

  /*
  .container-menu {
    margin-left: -6%;

    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  */

  .link_source {
    color: #2979ff;
    overflow: hidden;
    word-wrap: anywhere;
  }

  .notifier__container * {
    box-sizing: border-box;
  }

  .notifier__container-list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .notifier__notification {
    display: block;
    position: fixed;
    visibility: hidden;
    z-index: 10000;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .notifier__notification--material {
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    cursor: default;
    padding-top: 11px;
    padding-right: 26px;
    padding-bottom: 10px;
    padding-left: 26px;
  }

  .notifier__notification--material .notifier__notification-message {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: top;
    line-height: 32px;
    font-size: 15px;
  }

  .notifier__notification--material .notifier__notification-button {
    display: inline-block;
    transition: opacity 0.2s ease;
    opacity: 0.5;
    margin-right: -10px;
    margin-left: 10px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    padding: 6px;
    width: 32px;
    height: 32px;
    vertical-align: top;
  }

  .notifier__notification--material .notifier__notification-button:hover,
  .notifier__notification--material .notifier__notification-button:focus {
    opacity: 1;
  }

  .notifier__notification--material .notifier__notification-button:active {
    transform: scale(0.82);
    opacity: 1;
  }

  .notifier__notification--default {
    background-color: #444;
    color: #fff;
  }

  .notifier__notification--default .notifier__notification-button-icon {
    fill: #fff;
  }

  .notifier__notification--error {
    background-color: #f0ad4e;
    color: #fff;
  }

  .notifier__notification--error .notifier__notification-button-icon {
    fill: #fff;
  }

  .notifier__notification--info {
    background-color: #f0ad4e;
    color: #fff;
  }

  .notifier__notification--info .notifier__notification-button-icon {
    fill: #fff;
  }

  .notifier__notification--success {
    background-color: #5cb85c;
    color: #fff;
  }

  .notifier__notification--success .notifier__notification-button-icon {
    fill: #fff;
  }

  .notifier__notification--warning {
    background-color: #f0ad4e;
    color: #fff;
  }

  .notifier__notification--warning .notifier__notification-button-icon {
    fill: #fff;
  }

  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 20px 20px 0px 20px;
    border: 1px solid #888;
    width: 46%;
    top: 35%;
    left: calc(7% - 0px);
  }

  .alert_modal {
    border-bottom: 1px solid #e9ecef;
    margin-bottom: 10px;
  }

  .help_option {
    position: relative;

    &.bottom:hover {
      &:before {
        font-size: 13px;
        content: attr(text-info);
        position: absolute;
        z-index: 9;
        right: 0;
        color: #fff;
        font-size: 13px;
        padding: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        top: 53px;
        white-space: nowrap;
        font-family: "Muli", sans-serif;
        background-color: #121212;
        border-radius: 2px;
        -webkit-border-radius: 2px;
      }
    }
    &.bottom-after:hover {
      &::after {
        font-size: 13px;
        content: attr(text-info);
        position: absolute;
        z-index: 9;
        right: 0;
        color: #fff;
        font-size: 13px;
        padding: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        top: 44px;
        white-space: nowrap;
        font-family: "Muli", sans-serif;
        background-color: #121212;
        border-radius: 2px;
        -webkit-border-radius: 2px;
      }
    }

    &.top-after:hover {
      &::after {
        font-size: 13px;
        //content: attr(text-info);
        position: absolute;
        z-index: 9;
        right: 0;
        color: #fff;
        font-size: 13px;
        padding: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        top: -40px;
        white-space: nowrap;
        font-family: "Muli", sans-serif;
        background-color: #121212;
        border-radius: 2px;
        -webkit-border-radius: 2px;
      }
    }

    &.top-after.content:hover {
      &::after {
        font-size: 13px;
        content: attr(text-info);
        position: absolute;
        z-index: 9;
        left: 0 !important;
        color: #fff;
        font-size: 13px;
        padding: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        top: -40px;
        white-space: nowrap;
        font-family: "Muli", sans-serif;
        background-color: #121212;
        border-radius: 2px;
        -webkit-border-radius: 2px;
      }
    }
  }


  *::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #f5f5f5;
  }

  *::-webkit-scrollbar {
    width: 6px;
    background-color: #b1b4b9;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: transparent;
  }

  *:hover::-webkit-scrollbar-thumb {
    background-color: #b1b4b9;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(52, 58, 64, 0.55);
  }


}
