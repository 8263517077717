@import "../styles/colors";
@import '../styles/select-list';

.ace_gutter,
.ace_scrollbar {
  z-index: 1;
}




.full-screen__video {

  local-video {
    video::-webkit-media-text-track-display {
      left: 15px !important;
      width: calc(100% - 90px) !important;
    }

  }

}



local-video {

  video::cue {

    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 20px;
    }

    @media (max-width: 800px) and (min-width: 501px) {
      font-size: 1.3rem;
      line-height: 28px;
    }

    @media (min-width: 801px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}

.source__description {
  p {

    ul,
    ol {
      list-style: revert;
      padding: revert !important;
      margin: revert !important;

    }

    a {
      display: unset !important;
    }


    span.badge {
      background-color: gray;
      display: inline-block;
      background-color: #007bff;
      color: #fff;
      padding: 0px 4px;
      border-radius: 4px;
      font-weight: 600;
    }
  }

  table,
  tr,
  td {
    border: 1px solid;
    margin: auto;
  }


  //classes timeline by Amparo Pico
  .timeline__event {

    &:not(:last-of-type):after {
      content: "";
      width: 2px;
      height: calc(100% + 300px);
      background: #1d448e;
      position: absolute;
      top: 52%;
      z-index: 0;
    }

    &:before {
      content: "";
      position: absolute;
      background: #1d448e;
      border-radius: 100%;
      top: 50%;
      transform: translateY(-50%);
      border: 2px solid #1d448e;
    }

    // @media screen and (min-width:600px) {
    //   &:after {
    //     left: -3.5rem;
    //   }

    //   &:before {
    //     width: 5rem;
    //     height: 5rem;
    //     left: -6rem;
    //   }
    // }

    // @media screen and (min-width:599px) {

    &:after {
      left: -2rem;
    }

    &:before {
      width: 2rem;
      height: 2rem;
      left: -3rem;
    }


    // }


  }


  .timeline__event .timeline__event:last-child:after {
    content: none;
  }

  @media (max-width: 786px) {
    .timeline__event {
      flex-direction: column;
    }

    .timeline__event__icon {
      border-radius: 4px 4px 0 0;
    }
  }


  @media (max-width: 786px) {
    .timeline {
      width: 60vw !important;
    }

    .timeline__event {
      flex-direction: column;
    }

    .timeline_event_icon {
      border-radius: 4px 4px 0 0;
    }

    .media.flipped {
      flex-direction: column !important;
      align-items: center !important;
    }

  }

  .proc2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
    overflow: hidden;
  }

  .proc2_content {
    margin-top: 20px;
  }

  .proc2:hover {
    height: 300px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }


  .callout {
    margin: 1rem 0;
    padding: .5rem 1rem .5rem 1.5rem;
    background: #EEF4F8;
    border-left: 3px solid #9FCCE9;
    color: #5D7F95;
    font-size: 14px;
  }

  .callout.yellow {
    background: #FCF8F0;
    border-left-color: #F9DDA4;
    color: #B49A64;
  }

  .callout.red {
    background: #FCF0F0;
    border-left-color: #FFABAB;
    color: #AA6464;
  }

  .callout p {
    margin: 0;
  }

  //end clases Amparo
}


.classroom-content {
  width: 100%;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 599px) {
    &:not(.video_full):not(.wlcm) {
      height: calc(100% - 50px);
    }

    &.video_full,
    &.wlcm {
      height: 100%;

      .source__video {
        z-index: 5 !important;
      }
    }
  }

  @media screen and (min-width: 600px) {
    height: 100%;
  }
}


.lesson-content .modal-container.cartel {
  modal {
    height: calc(100% + 100px) !important;
  }
}

//estilo para las imagenes dentro del texto enriquecido
.source__description {
  img {
    max-width: 100%;
  }
}

.file__display {
  width: 100%;
  height: 100%;

  &.source {

    //Sources de la clase
    &:not(.full) {
      &>iframe {

        z-index: 2;
        top: -2%;
        left: -2%;
        height: 104%;
        width: 102%;
        border: none;
        position: relative;

        // top: -2px;
        // left: -3px;
        // height: calc(100% + 4px);
        // width: calc(100% + 5px);


        @media screen and (max-width: 600px) {
          top: -14px;
          left: -14px;
          height: calc(100% + 18px);
          width: calc(100% + 19px);
        }

      }
    }

    &.full {

      height: calc(100% - 50px);

      &>iframe {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: none;

      }

    }
  }

  &.file {

    //Archivo subido por el usuario
    &>iframe {

      z-index: 2;
      position: relative;
      top: -2px;
      left: -3px;
      border: none;
      height: calc(100% + 4px);
      width: calc(100% + 5px);



    }
  }


}

.file__image {
  img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-color: rgba(3, 27, 78, 0.1);
  }

}

.p__description {
  p {
    display: inline;
  }
}

.feedback .text * {
  font-size: 14px !important;
  background-color: $backgray-sidebar !important;
}

player-bar {

  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  background-color: $black;
  box-shadow: 2px 0px 2px 0px rgba(249, 246, 246, 0.2);

}

ul {
  margin: 0;
  padding: 0;
}

.section {
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: $backgray-sidebar;
}



p {
  margin: 0;
}

.classroom-section {
  position: relative;

  modal-dialog {
    .modal {
      z-index: 5;
    }
  }
}

source-viewer {
  &:not(.other_file) section:not(.doc) {
    height: fit-content;
  }

  &:not(.other_file) section.doc {
    // height: calc(100vh - 40px);    
    height: calc(100vh - 100px);
    border: 1px solid $border-color;
  }

  &.other_file section {
    height: 100%;
  }
}

source-viewer,
quiz-component {
  z-index: 2;
  width: 100%;
  height: 100%;

  &.other_file {
    z-index: 7;
    height: 100%;
    position: absolute;
  }



  .source-msj {
    color: $dark-blue-font;

    a {
      margin-top: 10px;
      color: $light-blue;

      i {
        font-size: 12px;
      }
    }
  }


  .answer-container.empty {
    position: relative;
    border: 1px solid #d93025;


    &.selection {
      padding: 8px 6px 22px;
    }

    &:not(.selection) {
      padding: 0 6px 22px;
    }


    &::after {
      content: "Necesita responder todas las preguntas";
    }


    &::after {
      height: fit-content;
      font-size: 13px;
      color: #d93025;
      padding: 6px;
      bottom: 0;
      left: 0;
      border-top-left-radius: 4px;
      align-items: center;
      position: absolute;
      width: fit-content;
      display: flex;
    }

    transition: 200ms border linear;
    -webkit-transition: 200ms border linear;
    -moz-transition: 200ms border linear;
    -ms-transition: 200ms border linear;
    -o-transition: 200ms border linear;
  }
}

local-video {
  z-index: 2;
  height: 100%;
  width: 100%;
}

youtube-video {
  z-index: 2;
  width: 100%;
  height: fit-content;

  &,
  .video-player,
  iframe {
    width: 100%;
    height: 100%;


  }

  .video-player {
    display: flex;
    min-height: 230px;
    position: relative;
  }
}

.lesson-content {

  &:not(.m-bile) {

    .full-screen__video {

      .captions-text {
        left: 15px !important;
        width: calc(100% - 90px) !important;


        .bg-caption-text {
          font-size: 24px !important;
          line-height: 2rem;
        }
      }

    }
  }

  &.m-bile {

    .full-screen__video {

      .captions-text {
        left: 15px !important;
        width: calc(100% - 90px) !important;
      }

    }


  }
}


// tabs inside component
.tabs>input,
.tabs-take-part>input {
  display: none;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


@mixin tabs($tabs) {
  @for $i from 1 through $tabs {
    &:nth-of-type(#{$i}) {
      &.active {
        ~.slide {
          left: calc((100% / #{$tabs}) * #{$i - 1});
        }
      }
    }
  }
}

.tabs {
  height: 100%;
  width: 100%;
  flex-direction: column;

  &>nav {
    justify-content: center;
    padding: 0 10px;
    background-color: $light-gray;

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      height: 35px;
      position: relative;
      margin: 0;

      $num-of-tabs: 0;

      &.tab-3 {
        $num-of-tabs: 3;

        li {
          min-width: 113px;
          flex: 1 1 calc(100% / #{$num-of-tabs});

          @include tabs($num-of-tabs);
        }


        .slide {
          width: calc(100% / #{$num-of-tabs});
        }
      }

      &.tab-2 {
        $num-of-tabs: 2;

        li {
          flex: 1 1 calc(100% / #{$num-of-tabs});

          @include tabs($num-of-tabs);
        }


        .slide {
          width: calc(100% / #{$num-of-tabs});
        }
      }

      li {


        &:first-of-type {
          &.active {
            ~.slide {
              left: 0;
            }
          }
        }

        &.active label {
          color: $light-blue;
        }

        label {
          font-size: 12px;
          cursor: pointer;
          display: flex;
          border-bottom: 0;
          height: 100%;
          align-items: center;
          color: $font-gray;
          justify-content: center;
        }

        &:not(:last-child) label {
          border-right-width: 0;
        }
      }

      .slide {
        background: $light-blue;
        height: 2px;
        position: absolute;
        left: 0;
        top: calc(100% - 2px);
        transition: left 0.3s ease-out;
        -webkit-transition: left 0.3s ease-out;
        -moz-transition: left 0.3s ease-out;
        -ms-transition: left 0.3s ease-out;
        -o-transition: left 0.3s ease-out;
      }
    }
  }
}

// tabs inside component

p {
  word-break: break-word;

  a {
    display: block;
  }
}

.classroom-sidebar {
  height: 100%;
  z-index: 7;
  overflow: hidden;



  @media screen and (max-width: 599px) {
    height: calc(100% - 50px) !important;
  }

  @media screen and (min-width:600px) {
    height: 100%;
  }

  .sidebar-container {
    height: 100%;
    display: block;
    overflow: hidden;

    @media screen and (min-width:400px) {
      min-width: 400px;
    }


    course-content,
    community,
    notes-list,
    activities {
      overflow: hidden;
      height: calc(100% - 50px);
    }



    .container-sidebar {
      height: 100%;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      box-shadow: 0px 0px 0px 1px rgba(195, 201, 215, 1) inset;
      -webkit-box-shadow: 0px 0px 0px 1px rgba(195, 201, 215, 1) inset;
      -moz-box-shadow: 0px 0px 0px 1px rgba(195, 201, 215, 1) inset;

      section {
        height: 100%;
        overflow: hidden;
        flex-direction: column;

      }

      .community-container {
        height: 100%;



        .options__group {
          width: calc(100% - 19px);
        }
      }

      .sidebar-header {
        display: flex;
        padding: 0 10px;
        height: 50px;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;

        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex: 1 1 100%;
          justify-content: space-between;

          li {
            width: 100%;
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;

            &.header__close {
              flex: 1 0 280px;
              color: $dark-blue-font;

              a {
                max-width: 30px;
                flex: auto;
                margin-right: 10px;
                color: $dark-blue-font;
              }
            }

            &.help__option {
              margin-right: 10px;
            }

            a {
              height: 100%;
              min-width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;


            }

            &:not(.header__close) {
              flex: 0 1 30px;
            }

            &:hover, &:hover a {
              color: $edutin-blue;
            }

          }
        }


      }

      .sidebar-content {
        height: 100%;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;

        .list,
        .feed,
        .participants {
          flex: 0 1 auto;
          overflow-y: scroll;
          box-sizing: border-box;

        }


        course-content,
        community,
        notes-list,
        activities {
          &.active {
            display: flex;
            flex-direction: column;
          }

          &:not(.active) {
            display: none;
          }
        }


        .empty-content {
          margin: 20px;
          position: relative;
          height: calc(100% - 90px);
          width: calc(100% - 40px);
          background-color: rgba(153, 164, 182, .15);
        }
      }
    }
  }

  &.close {
    flex: 0;
  }

  &.open {
    flex: 0 0 441px;

    .sidebar-header {
      ul.header__bttns li:not(.header__close) {
        position: relative;

        &:hover {

          a::after {

            content: attr(data-tooltip);
            top: 45px;
            z-index: 3;
            right: 0;
            color: #fff;
            font-size: 13px;
            padding: 5px 8px;
            position: absolute;
            width: fit-content;
            font-family: 'Muli', sans-serif;
            border-radius: 2px;
            background-color: $black;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            -o-border-radius: 2px;
          }



        }
      }
    }



  }

  &.open {
    &+.classroom-container {
      upload.true {
        .note {
          @media screen and (min-width: 1174px) {
            max-width: 48%;
          }
        }
      }
    }
  }

  &.close {
    &+.classroom-container {
      upload.true {
        .note {
          @media screen and (min-width: 734px) {
            max-width: 50%;
          }
        }
      }
    }
  }


  mark {
    color: #fff;
    padding: 1.5px;
    font-weight: 600;
    background-color: $edutin-blue;
    // background-color: rgba(29, 68, 142, 0.3) !important;
  }



  @mixin not-maximize {

    .community-container {
      flex-direction: column;
    }



    .container-sidebar .sidebar-header {
      ul.header__bttns {
        li {
          span {
            display: none;
          }
        }
      }
    }



    nav {
      ul {
        margin: 0;
      }
    }
  }

  &:not(.maximize) {
    @include not-maximize;
  }

  @mixin big-sidebar {
    //sidebar full screen
    flex: 0 0 100vw;

    .tabs {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;

      &>nav {
        height: fit-content;
        flex-basis: 100%;


        &::before {
          content: "";
          width: 186px;
          margin-right: 20px;
        }

        ul {
          width: 580px;
          margin: 0 8px 0 0px;
        }
      }

      section:first-of-type {

        width: 100%;
        height: 100%;
        min-width: 600px;
        max-width: 750px;
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;

        transition: all 0.25s;
        flex: 0 0 750px;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
      }
    }

    .community-container {

      flex-direction: row;
      justify-content: center;

      .participate-container {
        nav {
          display: flex;
          justify-content: center;

          ul {
            flex: 0 0 600px;
          }
        }

        section {
          display: flex;
          justify-content: center;
        }

      }
    }


    course-content {
      .section {
        transition: all 0.25s;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
      }
    }
  }



  @media screen and (min-width: 801px) {
    &.maximize {
      @include big-sidebar;
      width: 100%;
      position: absolute;

      .list,
      .feed,
      .participants {

        &.full-item {
          margin: 0px 4px 0px 10px;
        }

        &:not(.full-item) {
          margin: 0px 4px 10px 10px;
        }

      }


      .sidebar-content {
        #tabs-outer {
          display: flex;

          nav {
            display: flex;
          }
        }

        #tabs-inner {
          display: none;
        }
      }

    }


    &.open {

      .sidebar-content {
        #tabs-outer {
          display: none;
        }

        #tabs-inner {
          display: flex;
        }
      }

      .list,
      .feed,
      .participants {

        &.full-item {
          margin: 0px 4px 0px 10px;
        }

        &:not(.full-item) {
          margin: 0px 4px 10px 10px;
        }

      }

    }


  }

  @media screen and (max-width: 1069px) {

    &.maximize,
    &.open {
      section.info-card {
        display: none;
      }
    }


  }

  @media screen and (min-width: 1070px) {
    &.maximize {
      section.info-card {
        display: flex;
      }

      #tabs-outer {

        nav::after {
          content: "";
          width: 200px;
          margin-left: 20px;
        }

      }

    }



  }

  @media screen and (max-width: 800px) {

    &.maximize {
      @include not-maximize;

      width: 100%;
      height: 100%;
      flex-basis: 100%;
      position: absolute;


      .list,
      .feed,
      .participants {

        &.full-item {
          margin: 0px 4px 0px 10px;
        }

        &:not(.full-item) {
          margin: 0px 4px 10px 10px;
        }


      }

      .sidebar-content {
        #tabs-outer {
          display: none;
        }

        #tabs-inner {
          display: flex;
        }
      }

    }
  }

  community-users,
  community-comments,
  community-participate {
    overflow: hidden;
    flex: 1 1 auto;
    width: 100%;
    max-width: 750px;
  }

  @media screen and (min-width:600px) {
    transition: flex 0.1s ease-in-out;
    -webkit-transition: flex 0.1s ease-in-out;
    -moz-transition: flex 0.1s ease-in-out;
    -ms-transition: flex 0.1s ease-in-out;
    -o-transition: flex 0.1s ease-in-out;
  }


}

// change toggle sidebar size
.toggle-size {
  box-sizing: border-box;
  transition: transform 0.3s;
  width: 16px;
  height: 16px;

  &::before {
    content: "";
    display: block;
    width: 18px;
    height: 9px;
    border: solid 2px #4d4e4f;
    transition: width 0.3s;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
  }

  &.maximize::before {
    content: "";
    width: 9px;
    height: 18px;
  }

  &:hover {
    &::before {
      border-color: #5472aa;
    }
  }
}

.ng2-pdf-viewer-container {
  overflow-x: unset !important;

  .page {
    height: 100%;
  }
}

.options__group {

  &.comments_option_group {
    max-width: 750px;
  }

  &:not(.comments_option_group) {
    max-width: 742px;
  }

  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  // justify-content: center;
  margin: 10px 10px 0 10px;

  form {

    margin-bottom: 10px;

    a {
      cursor: pointer;
      flex-basis: 10%;
      text-align: center;

      &:nth-of-type(1) {
        font-size: 16px;
        font-weight: 600;
      }

      &:nth-of-type(2) {
        font-weight: bold;
      }
    }

    input {
      border: 0;
      outline: none;
      flex-basis: 80%;
      font-size: 15px;
      background: transparent;
      color: rgba(3, 27, 78, 0.8);

      &::placeholder {
        //color del placeholder del buscador
        font-size: 15px;
        color: rgba(3, 27, 78, 0.8);
      }
    }



    height: 40px;
    padding: 10px;

    flex: 1 1 100%;
    justify-content: space-between;
    display: flex;
    max-width: 745px;
    flex-basis: 100%;
    border-radius: 2px;
    align-items: center;
    border: 1px solid $border-color;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }



  a:not(.icon-cross) {
    margin: 0 0 10px 4px;
  }

  .select_list_2 {
    background-color: transparent;
    border: 1px solid #c3c9d7;

    ul {
      top: 40px;
    }
  }
}


.card-content {

  p.text {
    margin: 0;
    color: $dark-blue-font;

    *:not(a):not(mark) {
      color: $dark-blue-font !important;
      // tiene important porque hay veces que al copiar un texto de otra fuente y pegarlo en el editor se pega con el color css que trae 
    }
  }


}

comment-list,
quiz-component {
  .ace_editor {
    height: 160px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
}

community {
  &.active {
    display: flex;
    flex-direction: column;
  }

  &:not(.active) {
    display: none;
  }

  section {
    height: 100%;
    overflow: hidden;
    flex-direction: column;

    .comments {
      // height: calc(100% - 110px);

      @media screen and (max-width:412px) {
        height: calc(100% - 155px);
      }

      @media screen and (min-width:413px) {
        height: calc(100% - 110px);
      }

    }

  }


  .section {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
  }

  .community-container {
    display: flex;
    flex: auto;
    overflow-y: hidden;
    box-sizing: content-box;

    section:first-of-type:not(.info-card) {
      height: 100%;
      overflow: hidden;
      position: relative;

      &>div:not(.options__group):first-of-type {
        max-width: 600px;
        width: 100%;
      }



      &>.comments,
      &>.contributions {
        display: flex;
        flex-basis: 100%;
        min-height: 200px;
      }
    }

    @media screen and (max-width: 800px) {
      align-items: center;
    }
  }


  section.info-card {
    margin: 10px;
    flex: 1 1 0px;
    max-width: 200px;
    height: fit-content;

    .card {

      font-weight: 600;
      padding: 5px 15px 15px;

      div:nth-of-type(1) {
        display: flex;
        padding: 10px 0;
        font-size: 14px;
        align-items: center;
        line-height: 1.2rem;
        justify-content: space-around;

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.2rem;
        }

        i {
          color: #f8e71d;
          font-size: 30px;
        }

      }

      div:nth-of-type(2) {
        font-size: 13px;
        font-weight: 600;
        color: #687796;
        line-height: 1.2rem;

        a {
          color: $blue;
          margin: 10px 0 14px 0;
        }
      }
    }
  }

  a.title {
    color: $light-blue;
    text-transform: capitalize;

    &:visited {
      color: $edutin-blue;
    }
  }



  item-list {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.active {
      display: flex;
      flex-flow: column;
    }

    &:not(.active) {
      display: none;
    }

    .code .ace_editor {
      width: 100%;
      height: 150px;
      border-radius: 3px;
    }

    .text *:not(mark) {
      background-color: #fff !important;
    }
  }


  .options__group {

    justify-content: flex-end;

    label,
    a.btn {
      height: 42px;
      font-size: 14px;
    }

    label {
      flex: 0 1 140px;
      margin: 0 4px 5px 0;
    }

    a {
      flex: 0 1 150px;
    }

    search-bar {
      a {
        max-width: 40px;
      }
    }
  }

}



.classroom-sidebar {
  @media screen and (min-width:801px) and (max-width: 1100px) {
    &.open+.classroom-container {
      modal {


        div.cta a {
          width: 100%;

          &:first-of-type {
            margin-bottom: 1%;
          }
        }
      }
    }

    &.close+.classroom-container {
      modal div.cta a {
        width: 49%;

        &:first-of-type {
          margin-right: 1%;
        }

      }
    }
  }


  @media screen and (max-width:800px) {
    &+.classroom-container {
      modal div.cta a {
        width: 100%;

        &:first-of-type {
          margin-bottom: 1%;
        }
      }
    }
  }


  @media screen and (min-width: 1101px) {
    &+.classroom-container {
      modal div.cta a {
        width: 49%;

        &:first-of-type {
          margin-right: 1%;
        }
      }
    }
  }
}


modal {
  .flag {
    margin-bottom: -4px;
  }

  .modal-container {

    .title+div {
      .blue-text {
        color: $blue;
      }
    }
  }
}




quill-editor {
  width: 100%;
  background-color: #fff;

  .ql-toolbar.ql-snow {
    width: 200px;
    border: none;
    padding: 4px;
    margin: 0 10px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.20);

    .ql-picker-label {
      display: none;
    }

    .ql-picker-options {
      border: none;
      box-shadow: none;
      background-color: #fff;
      display: flex;
      min-width: 98px;
      padding: 0 8px;
      position: absolute;
      white-space: nowrap;
      outline: none;
    }

    .ql-formats {
      margin-right: 0;
    }
  }

  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border: none;
    min-height: 100px;
    overflow-y: auto;
    max-height: 200px;
    height: -moz-fit-content;
    height: -webkit-fill-available;
  }

}


.dropdown-menu-options {
  display: flex;
  position: relative;

  input[type='checkbox'] {
    &:checked+.dropdown-content {
      margin: 0;
      z-index: 5;
      display: flex;
      font-size: 1rem;
      text-align: left;
      position: absolute;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.12);
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
    }

    &:checked {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    display: none;
  }

  ul.dropdown-content {
    padding: 0;
    display: none;
    min-width: 100px;

    li {
      padding: 12px 15px;
      color: $dark-blue-font;

      &:hover {
        background-color: $backgray-sidebar;
        cursor: pointer;
      }
    }
  }

  label {
    width: 100%;
    border: none;
    color: $gray;
    outline: none;
    cursor: pointer;
    text-align: center;
    background-color: transparent;
  }

}


community-participate {
  .modal__container {
    flex: 0 1 85%;
  }
}

upload {
  .modal__container {
    flex: 0 1 50%;
  }

  &.true {
    ul.options li {
      position: relative;

      &:hover {

        a.toggle-size,
        a.icon-close {
          &::after {

            content: attr(data-tooltip);
            top: 45px;
            z-index: 3;
            right: 0;
            color: #fff;
            font-size: 13px;
            padding: 5px 8px;
            position: absolute;
            width: fit-content;
            font-family: 'Muli', sans-serif;
            border-radius: 2px;
            background-color: $black;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            -o-border-radius: 2px;
          }
        }



      }
    }

  }

  &:not(.full) {

    .notes-tabs {
      nav {
        justify-content: space-between;
      }
    }

    .options {

      li {

        width: 40px;

        .option__label {
          display: none;
        }

        .toggle-size:before {
          width: 18px;
          height: 12px;
        }

      }
    }
  }

  &.full {

    .notes-tabs {
      nav {
        justify-content: flex-end;
      }
    }

    .options {
      flex: 0 1 200px;

      li {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-right: 10px;

        .option__label {
          display: inline;
          margin: 0 6px;
        }

        .toggle-size:before {
          width: 18px;
          height: 9px;
        }
      }
    }

  }


  .modal__container .modal__body .spinner.spinner--button {
    top: -20%;
  }

}


@media screen and (max-width: 420px) {

  // community-participate,
  // answer-evaluation,
  // feedback {
  //   ul.icon-ellipsis-v.active div {
  //     right: -45px !important;
  //     flex-wrap: wrap;
  //     // height: 78px!important;
  //     min-width: 90vw;
  //     flex-direction: row;
  //   }
  // }

  upload ul.icon-ellipsis-v.active div {
    right: -105px !important;
    // right: -232px !important;
    // flex-wrap: wrap;    
    // min-width: 104vw;
    // flex-direction: row;
  }

}

community-participate,
upload,
answer-evaluation,
feedback {
  position: relative;
  font-size: .8rem;


  div.files {

    .files__group {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        margin-right: 8px;
      }


      &.disabled {
        span {
          color: rgba(92, 101, 113, .3);
        }

        ul,
        li {
          pointer-events: none;
          color: rgba(92, 101, 113, .3);
        }
      }
    }


    ul {

      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      ul {
        display: flex;
        position: relative;
        align-items: center;
        color: rgb(92, 101, 113);

        &.active {
          div {

            li {
              background-color: #f3f5f9;
            }

            display: flex;
            position: absolute;
            bottom: 31px;
            width: fit-content;
            height: 48px;
            padding: 10px;
            right: 0;
            min-height: 26px;
            background-color: #fff;
            box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.25);
            border-radius: 2px;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            -o-border-radius: 2px;
          }

        }

        &:not(.active) {
          div {
            display: none;
          }
        }
      }


      li {
        min-height: 26px;
        color: rgb(92, 101, 113);
        padding: 4px 6px;
        display: flex;
        cursor: pointer;
        min-width: 63px;
        font-size: 13px;
        border-radius: 2px;
        align-items: center;
        margin: 2px 3px 0 0;
        border: 1px solid #cccdcf;
        justify-content: space-evenly;


        &:hover {
          background-color: rgba(221, 221, 221, 0.6);
        }

        i {

          &.icon-link {
            font-size: 14px;
          }

          &:not(.icon-attached):not(.icon-link):not(.icon-play-circle-outline) {
            font-size: 10px;
          }


          &.icon-attached {
            font-size: 12px;
          }

          margin-right: 3px;

          &.icon-play-circle-outline {
            font-size: 12px;
          }
        }
      }
    }

    order: 1;
    z-index: 4;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 10px 0 10px 0;
    justify-content: space-between;
    background-color: $backgray-sidebar;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }


  .btn-group {
    margin-left: auto;
    padding: 10px 5px 0;

    .btn {
      font-size: .88rem;
      min-width: 100px;
      min-height: 38px;
      padding: .5rem 1rem;
    }


  }


  section {
    &>ul {
      li {
        i {
          display: none;
        }
      }
    }
  }



  upload-file {

    // height: fit-content;
    &.text,
    &.code {
      height: 100%;
    }
  }
}


.lesson-link {
  color: $gray;
  display: flex;
  font-size: 11.5px;
  padding: 10px;
  line-height: 15px;
  background-color: #f3f5f9;

  p {
    font-size: 11px;
    color: $font-gray;
  }

  i {
    margin: 3px 6px 0 0;
  }
}



upload {
  &:not(.evaluation_upload) {
    bottom: 0;
    z-index: 6;
    width: 100%;
    height: 280px;
    position: absolute;
    background-color: $backgray-sidebar;
    box-shadow: -4px 0px 4px 1px rgba(50, 50, 93, 0.25);
    // box-shadow: 0 0px 5px 1px rgba(50, 50, 93, 0.25), 0 1px 3px 0px rgba(0, 0, 0, 0.3);

    .notes-container {
      padding: 10px 2%;
    }

    .editor__body {
      overflow-y: scroll;

      .editor__container {
        padding: 8px 2% 0 2%;
      }
    }

    .notes__footer {
      padding: 8px 2% 0 2%;
      width: calc(100% - 6px);
    }
  }

  &.evaluation_upload {
    .notes__footer {
      width: 100%;
      padding-top: 8px;
    }
  }


  &[class$="min"] {
    display: none;
  }

  &.medium {
    height: 100%;
  }

  &.full {


    height: 100%;

    .notes-container {
      padding: 0;
    }

    .notes-tabs {

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    ul.tabs-v {
      padding: 10px 0;
      margin: 0 10px;
      min-width: 130px;
      height: fit-content;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;


      li {
        * {
          cursor: pointer;
        }

        i {
          display: flex;
          margin-right: 10px;
        }


        height: 30px;
        display: flex;
        padding: 0 20px;
        font-size: .8rem;
        align-items: center;
        flex-direction: row;

        &:not(.active) {
          color: $dark-blue-font;
        }

        &.active {
          color: $blue;
          background-color: $backgray-sidebar;
        }
      }

    }

    nav {
      width: 100%;
      height: 59px;
      border-bottom: 1px solid $border-color;
    }

    section {
      margin: 10px;
      max-width: 700px;
    }
  }
}

.caption-visual-line * {
  font-family: Roboto, "Arial Unicode Ms", Arial, Helvetica, Verdana, "PT Sans Caption", sans-serif;
  color: #fff !important;
  font-weight: 500 !important;
}




.help__option {
  position: relative;

  &:hover {

    &:before {
      font-size: 13px;
      content: attr(text-info);
      position: absolute;
      z-index: 9;
      line-height: 1.6;

      right: 0;
      color: #fff;
      font-size: 13px;
      padding: 20px;
      width: 300px;
      font-family: 'Muli', sans-serif;
      background-color: $black;

      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
    }

    .icon-question-circle {
      color: $edutin-blue;
    }
  }
}

upload {
  &:not(.full) {
    .help__option {
      &:hover {

        &:before {

          top: 30px;
          right: -30px;

        }
      }
    }
  }

  &.full {
    .help__option {
      &:hover {

        &:before {

          top: 35px;
          right: -30px;

        }
      }
    }
  }
}


sidebar {
  height: 100%;
  display: block;
  overflow: hidden;

  .help__option {
    &:hover {

      &:before {

        right: 0;
        top: 45px;

      }
    }
  }
}



.modal-container.full-screen__source .source__doc {
  .file-container {
    height: 100%;
  }
}




.summary {
  font-size: 14px;


  .summary__title {
    margin: 10px 0;
    font-size: 15px;
    font-weight: 600;
    margin: 18px 0 12px 0;
  }

  .summary__container {


    p {
      &:not(.summary__info) {
        margin-bottom: 14px;
      }

      span {
        margin-right: 8px;
        font-weight: bolder;

        &.icon-answers {
          color: $edutin-blue;
        }

        &.icon-activity {
          color: #368f8b;
        }

        &.icon-rate {
          color: #FFC107;
        }
      }

      &.medal {
        display: flex;
        align-items: center;
        height: 24px;

        .medal-svg {
          height: 20px;
          margin: 0 8px 0 1px;
          // margin: 0 16px 0 1px;
        }

      }



    }

    &+p {
      text-align: right;
      padding: 1rem 8px 1rem 1rem;
    }

    .summary__footer {
      display: flex;
      margin: 15px 0 0;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: 400px) {
        flex-wrap: wrap;

        .btn {
          height: 42px;
          width: 100%;
        }
      }

      .btn__info {
        margin-right: 20px;
      }

      .btn__ligth-blue {
        text-align: center;
        padding: 10px 15px;
      }
    }

    padding: 1rem;
    height: auto;
    padding: 15px 30px;
    color: $dark-blue-font;
    background-color: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
  }

  .summary__info {
    color: $gray;
    margin-top: 10px;
  }


  .btn-group {
    max-width: 440px;
    margin-left: auto;
    flex-wrap: wrap;

    .btn {
      padding: .5rem 1.3rem;
      height: 42px;
      min-width: 180px;
      flex: 1 1 49%;
      margin: 2px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
