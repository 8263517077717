.icon-cross {
  font-size: 16px !important;
  font-weight: bolder !important;
}

@font-face {
  font-family: 'edutin-icons';
  src:  url('../../assets/fonts/edutin-icons.eot?h5i39j');
  src:  url('../../assets/fonts/edutin-icons.eot?h5i39j#iefix') format('embedded-opentype'),
    url('../../assets/fonts/edutin-icons.ttf?h5i39j') format('truetype'),
    url('../../assets/fonts/edutin-icons.woff?h5i39j') format('woff'),
    url('../../assets/fonts/edutin-icons.svg?h5i39j#edutin-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'edutin-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-forum:before {
  content: "\e967";
}
.icon-title:before {
  content: "\e968";
}
.icon-bullet:before {
  content: "\e969";
}
.icon-edit:before {
  content: "\e965";
}
.icon-copy:before {
  content: "\e966";
}
.icon-fbook:before {
  content: "\e964";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-github:before {
  content: "\e901";
}
.icon-google:before {
  content: "\e902";
}
.icon-twitter:before {
  content: "\e903";
}
.icon-www:before {
  content: "\e904";
}
.icon-youtube:before {
  content: "\e905";
}
.icon-certificate:before {
  content: "\e95c";
}
.icon-play-circle-outline:before {
  content: "\e917";
}
.icon-feedback:before {
  content: "\e95b";
}
.icon-community:before {
  content: "\e95a";
}
.icon-evaluacion-source:before {
  content: "\e93d";
}
.icon-text-source:before {
  content: "\e958";
}
.icon-video-source:before {
  content: "\e959";
}
.icon-arrow-up-to:before {
  content: "\e906";
}
.icon-arrow-point-to:before {
  content: "\e957";
}
.icon-subtitles:before {
  content: "\e955";
}
.icon-attached:before {
  content: "\e907";
}
.icon-code:before {
  content: "\e908";
}
.icon-image:before {
  content: "\e90f";
}
.icon-link:before {
  content: "\e912";
}
.icon-add-text:before {
  content: "\e915";
}
.icon-answers:before {
  content: "\e918";
}
.icon-rate:before {
  content: "\e919";
}
.icon-course-content:before {
  content: "\e943";
}
.icon-contributions:before {
  content: "\e944";
}
.icon-contributed:before {
  content: "\e945";
}
.icon-cumulative-hours:before {
  content: "\e946";
}
.icon-follow:before {
  content: "\e947";
}
.icon-home:before {
  content: "\e948";
}
.icon-h-screen:before {
  content: "\e949";
}
.icon-message:before {
  content: "\e94a";
}
.icon-next:before {
  content: "\e94b";
}
.icon-notes:before {
  content: "\e94c";
}
.icon-pause:before {
  content: "\e94d";
}
.icon-user:before {
  content: "\e94e";
}
.icon-questions:before {
  content: "\e94f";
}
.icon-circle-questions:before {
  content: "\e950";
}
.icon-tick-outline:before {
  content: "\e951";
}
.icon-back:before {
  content: "\e952";
}
.icon-take-part:before {
  content: "\e953";
}
.icon-v-screen:before {
  content: "\e954";
}
.icon-info:before {
  content: "\e909";
}
.icon-cloud-error:before {
  content: "\e90a";
}
.icon-back-arrow:before {
  content: "\e90b";
}
.icon-earth:before {
  content: "\e93b";
}
.icon-lock:before {
  content: "\e93c";
}
.icon-select-arrow:before {
  content: "\e93e";
}
.icon-forward:before {
  content: "\e90c";
}
.icon-delete:before {
  content: "\e90d";
}
.icon-like:before {
  content: "\e90e";
}
.icon-view:before {
  content: "\e910";
}
.icon-volume:before {
  content: "\e911";
}
.icon-volume-medium:before {
  content: "\e913";
}
.icon-volume-high:before {
  content: "\e914";
}
.icon-ellipsis-h:before {
  content: "\e916";
}
.icon-arrow-down:before {
  content: "\e91a";
}
.icon-arrow-up:before {
  content: "\e91b";
}
.icon-save:before {
  content: "\e91c";
}
.icon-next1:before {
  content: "\e91d";
}
.icon-play-arrow:before {
  content: "\e91e";
}
.icon-return:before {
  content: "\e91f";
}
.icon-send:before {
  content: "\e920";
}
.icon-tick:before {
  content: "\e921";
}
.icon-comments:before {
  content: "\e922";
}
.icon-plus:before {
  content: "\e923";
}
.icon-alarms:before {
  content: "\e924";
}
.icon-apuntes:before {
  content: "\e925";
}
.icon-minus:before {
  content: "\e926";
}
.icon-search:before {
  content: "\e927";
}
.icon-question-circle:before {
  content: "\e928";
}
.icon-underline:before {
  content: "\e929";
}
.icon-arrow-left:before {
  content: "\e92a";
}
.icon-bold:before {
  content: "\e92b";
}
.icon-play-circle:before {
  content: "\e92c";
}
.icon-italic:before {
  content: "\e92d";
}
.icon-arrow-right:before {
  content: "\e92e";
}
.icon-cross:before {
  content: "\e92f";
}
.icon-close:before {
  content: "\e95d";
}
.icon-pause-circle:before {
  content: "\e930";
}
.icon-new-note:before {
  content: "\e931";
}
.icon-select:before {
  content: "\e932";
}
.icon-volume-low:before {
  content: "\e933";
}
.icon-eye:before {
  content: "\e934";
}
.icon-alert:before {
  content: "\e935";
}
.icon-fullscreen:before {
  content: "\e936";
}
.icon-activity:before {
  content: "\e937";
}
.icon-document:before {
  content: "\e938";
}
.icon-settings:before {
  content: "\e939";
}
.icon-download:before {
  content: "\e93a";
}
.icon-download-video:before {
  content: "\e93f";
}
.icon-download-bottom:before {
  content: "\e940";
}
.icon-flecha:before {
  content: "\e941";
}
.icon-fullscreen-exit:before {
  content: "\e942";
}
.icon-pause1:before {
  content: "\e956";
}
.icon-reply:before {
  content: "\e95e";
}
.icon-send-arrow:before {
  content: "\e95f";
}
.icon-text:before {
  content: "\e960";
}
.icon-ellipsis-v:before {
  content: "\e961";
}
.icon-reload:before {
  content: "\e962";
}
.icon-quiz:before {
  content: "\e963";
}


// ________________________________________DESIGNER___________________________________________________
@font-face {
  font-family: 'creatoricon';
  src:  url('../../assets/fonts/creatoricon.eot?r8o2b0');
  src:  url('../../assets/fonts/creatoricon.eot?r8o2b0#iefix') format('embedded-opentype'),
    url('../../assets/fonts/creatoricon.ttf?r8o2b0') format('truetype'),
    url('../../assets/fonts/creatoricon.woff?r8o2b0') format('woff'),
    url('../../assets/fonts/creatoricon.svg?r8o2b0#creatoricon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'creatoricon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-desplazar:before {
  content: "\e900";
  color: #9aa4b8;
}
.icon-uniE901:before {
  content: "\e901";
}
.icon-uniE902:before {
  content: "\e902";
}
.icon-activo-configuracion:before {
  content: "\e903";
}
.icon-contenido:before {
  content: "\e904";
}
.icon-activo-cursos:before {
  content: "\e905";
}
.icon-activo-estadisticas:before {
  content: "\e906";
}
.icon-activo-estrella:before {
  content: "\e907";
}
.icon-uniE908:before {
  content: "\e908";
}
.icon-uniE909:before {
  content: "\e909";
}
.icon-uniE90A:before {
  content: "\e90a";
}
.icon-uniE90B:before {
  content: "\e90b";
}
.icon-configuracion:before {
  content: "\e90c";
}
.icon-cursos:before {
  content: "\e90f";
}
.icon-uniE910:before {
  content: "\e910";
}
.icon-uniE911:before {
  content: "\e911";
}
.icon-uniE912:before {
  content: "\e912";
}
.icon-uniE913:before {
  content: "\e913";
}
.icon-estrella:before {
  content: "\e914";
}
.icon-uniE915:before {
  content: "\e915";
}
.icon-uniE916:before {
  content: "\e916";
}
.icon-alert:before {
  content: "\e917";
}
.icon-validate:before {
  content: "\e918";
}
.icon-view:before {
  content: "\e919";
}
.icon-save:before {
  content: "\e91a";
}
.icon-to-attach:before {
  content: "\e91b";
}
.icon-contributions:before {
  content: "\e91c";
}
.icon-tick:before {
  content: "\e91d";
}
.icon-comments:before {
  content: "\e91e";
}
.icon-search:before {
  content: "\e91f";
}
.icon-sort:before {
  content: "\e920";
}
.icon-arrow-circle-left:before {
  content: "\e921";
}
.icon-check-circle:before {
  content: "\e922";
}
.icon-sync:before {
  content: "\e923";
}
.icon-cross:before {
  content: "\e924";
}
.icon-attached:before {
  content: "\e925";
}
.icon-bell:before {
  content: "\e926";
}
.icon-select:before {
  content: "\e927";
}
.icon-nuevo-curso:before {
  content: "\e928";
}
.icon-aportes:before {
  content: "\e929";
}
.icon-alert1:before {
  content: "\e92a";
}
.icon-arrow-right:before {
  content: "\e92b";
}
.icon-activity:before {
  content: "\e92c";
}
.icon-arrow-down:before {
  content: "\e92d";
}
.icon-arrow-up:before {
  content: "\e92e";
}
.icon-back:before {
  content: "\e92f";
}
.icon-close:before {
  content: "\e930";
}
.icon-cursos1:before {
  content: "\e931";
}
.icon-detalles:before {
  content: "\e932";
}
.icon-edit:before {
  content: "\e933";
}
.icon-estadisticas1:before {
  content: "\e934";
}
.icon-reply:before {
  content: "\e935";
}
.icon-eye:before {
  content: "\e936";
}
.icon-image:before {
  content: "\e937";
}
.icon-help:before {
  content: "\e938";
}
.icon-like:before {
  content: "\e939";
}
.icon-lupa-rotate:before {
  content: "\e93a";
}
.icon-lupa:before {
  content: "\e93b";
}
.icon-preguntas:before {
  content: "\e93c";
}
.icon-trash:before {
  content: "\e93d";
}
.icon-caret-down:before {
  content: "\e93e";
}
.icon-add:before {
  content: "\e93f";
}
.icon-ellipsis-v:before {
  content: "\e940";
}
.icon-times:before {
  content: "\e941";
}
.icon-arrow-left:before {
  content: "\e942";
}
.icon-next:before {
  content: "\e943";
}
.icon-attached1:before {
  content: "\e944";
}
.icon-code:before {
  content: "\e945";
}
.icon-image1:before {
  content: "\e946";
}
.icon-link:before {
  content: "\e947";
}
.icon-add-text:before {
  content: "\e948";
}
.icon-play-circle-outline:before {
  content: "\e949";
}
.icon-answers:before {
  content: "\e94a";
}
.icon-rate:before {
  content: "\e94b";
}
.icon-course-content:before {
  content: "\e94c";
}
.icon-contributions1:before {
  content: "\e94d";
}
.icon-contributed:before {
  content: "\e94e";
}
.icon-cumulative-hours:before {
  content: "\e94f";
}
.icon-follow:before {
  content: "\e950";
}
.icon-home:before {
  content: "\e951";
}
.icon-message:before {
  content: "\e953";
}
.icon-next1:before {
  content: "\e954";
}
.icon-saved:before {
  content: "\e955";
}
.icon-saved-outline:before {
  content: "\e956";
}
.icon-close1:before {
  content: "\e957";
}
.icon-arrow-point-to:before {
  content: "\e958";
}
.icon-notes:before {
  content: "\e959";
}
.icon-pause:before {
  content: "\e95a";
}
.icon-user:before {
  content: "\e95b";
}
.icon-questions:before {
  content: "\e95c";
}
.icon-circle-questions:before {
  content: "\e95d";
}
.icon-tick-outline:before {
  content: "\e95e";
}
.icon-back1:before {
  content: "\e95f";
}
.icon-take-part:before {
  content: "\e960";
}
.icon-info:before {
  content: "\e962";
}
.icon-cloud-error:before {
  content: "\e963";
}
.icon-back-arrow:before {
  content: "\e964";
}
.icon-earth:before {
  content: "\e965";
}
.icon-lock:before {
  content: "\e966";
}
.icon-select-arrow:before {
  content: "\e967";
}
.icon-forward:before {
  content: "\e968";
}
.icon-delete:before {
  content: "\e969";
}
.icon-like1:before {
  content: "\e96a";
}
.icon-view1:before {
  content: "\e96b";
}
.icon-volume:before {
  content: "\e96c";
}
.icon-volume-medium:before {
  content: "\e96d";
}
.icon-volume-high:before {
  content: "\e96e";
}
.icon-ellipsis-h:before {
  content: "\e96f";
}
.icon-arrow-down1:before {
  content: "\e970";
}
.icon-arrow-up1:before {
  content: "\e971";
}
.icon-save1:before {
  content: "\e972";
}
.icon-next11:before {
  content: "\e973";
}
.icon-play-arrow:before {
  content: "\e974";
}
.icon-return:before {
  content: "\e975";
}
.icon-send:before {
  content: "\e976";
}
.icon-tick1:before {
  content: "\e977";
}
.icon-comments1:before {
  content: "\e978";
}
.icon-plus:before {
  content: "\e979";
}
.icon-alarms:before {
  content: "\e97a";
}
.icon-apuntes:before {
  content: "\e97b";
}
.icon-minus:before {
  content: "\e97c";
}
.icon-search1:before {
  content: "\e97d";
}
.icon-question-circle:before {
  content: "\e97e";
}
.icon-underline:before {
  content: "\e97f";
}
.icon-arrow-left1:before {
  content: "\e980";
}
.icon-bold:before {
  content: "\e981";
}
.icon-play-circle:before {
  content: "\e982";
}
.icon-italic:before {
  content: "\e983";
}
.icon-arrow-right1:before {
  content: "\e984";
}
.icon-cross1:before {
  content: "\e985";
}
.icon-pause-circle:before {
  content: "\e986";
}
.icon-new-note:before {
  content: "\e987";
}
.icon-select1:before {
  content: "\e988";
}
.icon-volume-low:before {
  content: "\e989";
}
.icon-eye1:before {
  content: "\e98a";
}
.icon-alert2:before {
  content: "\e98b";
}
.icon-fullscreen:before {
  content: "\e98c";
}
.icon-activity1:before {
  content: "\e98d";
}
.icon-document:before {
  content: "\e98e";
}
.icon-settings:before {
  content: "\e98f";
}
.icon-download:before {
  content: "\e990";
}
.icon-download-video:before {
  content: "\e991";
}
.icon-download-bottom:before {
  content: "\e992";
}
.icon-flecha:before {
  content: "\e993";
}
.icon-fullscreen-exit:before {
  content: "\e994";
}
.icon-pause1:before {
  content: "\e995";
}
.icon-reply1:before {
  content: "\e996";
}
.icon-send-arrow:before {
  content: "\e997";
}
.icon-text:before {
  content: "\e998";
}
.icon-ellipsis-v1:before {
  content: "\e999";
}
.icon-reload:before {
  content: "\e99a";
}
.icon-quiz:before {
  content: "\e99b";
}
